import {useQuery} from "react-query";
import {users} from "../../../API/TT_ELD/Users/user";
import {reportController} from "../../../API/Tms/report";

export const useUsersData = (companyId: undefined| number | string, role: undefined | string,status: boolean | null| string, driverName: any,driverEmail: undefined | string | number ,userName:undefined | string | number ,licenseNumber:undefined | string | number ,uid:string,companyUID:string, skip: string | number = 1): object => {
    return useQuery([
            `users/${driverName || 'all'}`, companyId, role,status, driverName,driverEmail,userName,licenseNumber,uid,companyUID, skip],
        () => users.read(companyId, role,status, driverName,driverEmail,userName,licenseNumber,uid,companyUID, skip),
        { refetchOnWindowFocus: false })
}

export const useUserData = (userId: number | string | undefined): any => {
    return useQuery([
            `users/${userId}`, userId],
        () => users.userData(userId),
        { refetchOnWindowFocus: false, staleTime: 5 * 60 * 1000 })
}

export const useTrackingRang = ( skip : string|number, date: string| number | any,driverId:string | undefined): object => {
    return useQuery(
        [
            `admin/report${ skip || 'all'}`,skip , date,driverId
        ],
        () => users.trackingByRang(skip, date, driverId),
        {refetchOnWindowFocus: false}
    );
};


// export const useFMCSADriver = (driverId:string| undefined, skip : string|number, date: string| number | any, uid:string): object => {
//     return useQuery(
//         [
//             `admin/fmcsa-drive${ skip || 'all'}`,skip , date,driverId,uid
//         ],
//         () => users.fmcsaDriver(driverId , skip, date, uid),
//         {refetchOnWindowFocus: false}
//     );
// };